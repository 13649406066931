<template>
  <VueSlickCarousel class="features-carousel" :slidesToShow="slidesToShow" :arrows="false" dots autoplay>
    <div v-for="(feature, idx) in features" :key="idx" class="feature d-flex align-items-center">
      <div class="item">
        <div class="pretitle text-red text-uppercase fw-bolder mb-3">{{ $t('try.how_it_works') }}</div>
        <div class="title text-white fw-bolder mb-3" v-html="$t(`try.features.${feature.title}`)"/>
        <div class="description text-white mb-5">{{ $t(`try.features.${feature.description}`) }}</div>
        <b-button class="get-btn bg-red rounded-pill fs-6 px-5 py-3" size="lg" @click="$emit('getNow')">
          {{ $t('try.get_it_now') }}
        </b-button>
        <b-button
            class="watch-video-btn text-white rounded-pill fs-6 py-3"
            variant="transparent"
            size="lg"
            @click="$emit('playVideo')"
        >
          <div class="d-flex align-items-center">
            <img src="@/assets/img/icons/play.svg" alt="">
            <span class="px-2">{{ $t('try.watch_video') }}</span>
          </div>
        </b-button>
      </div>
      <div class="image">
        <img :src="require(`@/assets/img/${feature.image}`)" alt="">
      </div>
    </div>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  components: {
    VueSlickCarousel
  },
  props: {},
  data: () => ({
    slidesToShow: 1,
    features: [
      {
        image: 'record-incoming-call.png',
        title: 'record_incoming_call',
        description: 'record_incoming_calls_in_one_click'
      },
      {
        image: 'record-outcoming-call.png',
        title: 'record_outcoming_call',
        description: 'start_recording_and_call'
      },
      {
        image: 'recording-history.png',
        title: 'recording_history',
        description: 'history_of_all_entries'
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.features-carousel::v-deep {
  .feature {
    padding: 100px 150px;

    .item {
      flex: 1 1 0;
      text-align: left;

      .pretitle {
        font-size: 22px;
      }

      .title {
        font-size: 46px;
      }

      .description {
        font-size: 20px;
      }

      .get-btn {
        margin-right: 15px;
      }

      .watch-video-btn {
        &:hover {
          border-color: #FFF;
        }
      }
    }

    .image {
      img {
        width: 300px;
        margin: auto;
      }
    }

    > div {
      flex: 1 1 0;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 25px;

    li {
      button {
        &:before {
          color: #DD3D37;
          font-size: 15px;
        }
      }

      &:not(.slick-active) {
        button {
          &:before {
            color: #FFF;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .features-carousel::v-deep {
    .feature {
      padding: 50px 25px;
      flex-direction: column;

      .item {
        flex: 1 1 0;
        text-align: center;

        .pretitle {
          font-size: 18px;
        }

        .title {
          font-size: 24px;
        }

        .description {
          font-size: 16px;
        }

        .get-btn {
          display: none;
        }

        .watch-video-btn {
          display: none;
        }
      }

      .image {
        img {
          width: 200px;
        }
      }

      > div {
        flex: 1 1 0;
      }
    }

    .slick-dots {
      bottom: 15px;

      li {
        button {
          &:before {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
