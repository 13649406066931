<template>
  <div class="try-wrapper m-auto">
    <div class="top-wrapper bg-dark-blue d-flex align-items-center">
      <div class="container">
        <div class="row top-info-container">
          <div class="col-12 col-md-6 col-lg-6 text">
            <div class="pretitle text-red text-uppercase fw-bolder mb-3">{{ $t('try.business_app') }}</div>
            <div class="title text-white fw-bolder mb-3">Call Recorder</div>
            <div class="description text-white mb-5">{{ $t('try.description') }}</div>
            <div>
              <b-button class="get-btn bg-red rounded-pill fs-6 px-5 py-3" size="lg" @click="getNow">
                {{ $t('try.get_it_now') }}
              </b-button>

              <b-button class="watch-video-btn text-white rounded-pill fs-6 py-3" variant="transparent" size="lg"
                        @click="playVideo">
                <div class="d-flex align-items-center">
                  <img src="@/assets/img/icons/play.svg" alt="">
                  <span class="px-2">{{ $t('try.watch_video') }}</span>
                </div>
              </b-button>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 image">
            <img class="col-12 col-md-12 col-lg-12"
                 srcset="@/assets/img/iPhone-12.png 320w, @/assets/img/iPhone-12@2x.png 480w, @/assets/img/iPhone-12@3x.png 800w"
                 sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 282px"
                 src="@/assets/img/iPhone-12@3x.png" alt="iPhone-12"/>
          </div>
        </div>
      </div>
    </div>
    <div class="video-wrapper mt-5" ref="youtubeVideo">
      <div class="title text-red text-uppercase fw-bolder mb-2">{{ $t('try.video') }}</div>
      <div class="description fs-3 fw-bolder mb-2">{{ $t('try.watch_how_it_works') }}</div>
      <div class="text-secondary mb-5">{{ $t('try.thirty_seconds_video') }}</div>
      <youtube class="video-frame" ref="youtube" :video-id="videoId"/>
    </div>
    <div class="features-wrapper mt-5">
      <div class="title text-red text-uppercase fw-bolder mb-2">{{ $t('try.main_functions') }}</div>
      <div class="description fs-3 fw-bolder mb-md-5 mb-2">{{ $t('try.our_features') }}</div>
      <div class="container">
        <div class="row">
          <div v-for="(feature, idx) in features" :key="idx" class="col-md-4 feature d-flex flex-column text-left p-5"
               :class="feature.class">
            <img :src="require(`@/assets/img/icons/${feature.icon}`)" alt="">
            <div class="fs-4 fw-bold mt-3" v-html="$t(`try.features.${feature.title}`)"></div>
            <div class="mt-3">{{ $t(`try.features.${feature.description}`) }}</div>
          </div>
        </div>
      </div>
      <b-button class="get-btn bg-red rounded-pill fs-6 py-3 mt-4" size="lg" @click="getNow">
        {{ $t('try.get_it_now') }}
      </b-button>
    </div>

    <div class="features-carousel-wrapper bg-dark-blue mt-5">
      <features-carousel @getNow="getNow" @playVideo="playVideo"/>
    </div>

    <div class="reviews-wrapper mt-5">
      <div class="title text-red text-uppercase fw-bolder mb-2">{{ $t('try.comments') }}</div>
      <div class="description fs-3 fw-bolder mb-2">{{ $t('try.people_about_us') }}</div>
      <div class="text-secondary mb-5">{{ $t('try.all_our_users_love_us') }}</div>

      <comments-carousel/>
    </div>

    <div class="fixed-btn-wrapper bg-dark-blue position-fixed align-items-center p-3">
      <b-button class="get-btn bg-red rounded-pill fs-6 px-5 py-3 w-100" size="lg" @click="getNow">
        {{ $t('try.get_it_now') }}
      </b-button>
      <b-button class="watch-video-btn text-white rounded-pill fs-6 py-3" variant="transparent" size="lg"
                @click="playVideo">
        <div class="d-flex align-items-center">
          <img src="@/assets/img/icons/play.svg" alt="">
          <span class="px-2">{{ $t('try.watch_video') }}</span>
        </div>
      </b-button>
    </div>

    <b-modal
        id="get_now_modal"
        hide-header
        hide-footer
        hide-header-close
        centered
    >
      <div class="text-center">
        <div class="fs-4">{{ $t('try.email') }}</div>
        <div class="text-secondary">{{ $t('try.enter_your_email_to_get_access') }}</div>
        <input
            v-model="email"
            type="text"
            class="try-text-input text-center w-100 mt-4"
            placeholder="example@gmail.com"
        >
        <div class="agreements-msg text-secondary mt-3">
          {{ $t('try.by_continuing_you_agree') }}
          <a href="https://simple-recorder.com/onboarding/terms/" target="_blank">Terms & Conditions</a>
          {{ $t('try.and') }}
          <a href="https://simple-recorder.com/onboarding/policy/" target="_blank">Privacy Policy</a>
          {{ $t('try.and_give_permission_to_send_content') }}
        </div>
        <b-button
            class="get-btn bg-red rounded-pill fs-6 px-5 py-3 mt-3"
            size="lg"
            :disabled="!isEmailValid"
            @click="submit"
        >
          {{ $t('try.get_it_now') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import {mapGetters} from 'vuex';
import config from '@/config';
import FeaturesCarousel from '@/components/FeaturesCarousel';
import CommentsCarousel from '@/components/CommentsCarousel';

export default {
  components: {
    FeaturesCarousel,
    CommentsCarousel
  },
  data: () => ({
    email: '',
    videoId: 'hDMyB1We9E4',
    utmTagsRequired: ['af_pid', 'af_c', 'af_channel', 'af_c_id', 'af_adset', 'af_adset_id', 'af_ad', 'af_ad_id', 'utm_campaign', 'utm_adset'],
    features: [
      {
        icon: 'edit.svg',
        title: 'replay_and_rename_records',
        description: 'change_name_of_audio'
      },
      {
        icon: 'microphone.svg',
        title: 'practical_recording',
        description: 'start_recording_in_one_click',
        class: 'bg-red'
      },
      {
        icon: 'share.svg',
        title: 'share_and_export',
        description: 'there_are_no_deadlines'
      }
    ]
  }),
  async beforeMount() {
    this.initFBPixel(this.fbPixelId);
    this.initClarity(config.clarityId.tryId)
    Vue.use(VueGtag, {config: {id: config.gaId.try.prod}});

    this.setAuthHashCookie();
    await this.$store.dispatch('appStore/getTargetingValue');
  },
  mounted() {
    this.amplitudeEvent('Welcome shown');
    this.gtagEvent('Page_View', {event_category: 'View', event_label: 'Page_View_Try'});
    this.pixelEvent('PageView');
  },
  computed: {
    ...mapGetters({
      targetingValue: 'appStore/targetingValue'
    }),
    player() {
      return this.$refs.youtube.player;
    },
    isEmailValid() {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return pattern.test(this.email);
    },
    isAuthKeyInCookies() {
      return this.$cookies.isKey('auth_key');
    },
    isEmailExistsInCookies() {
      return this.$cookies.get('auth_email') === this.email;
    },
    isMobileDevice() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    isRedirectScenario() {
      return true;
      // return !this.isMobileDevice || this.targetingValue !== 'ready' || !this.isAllUtmTagsPresent;
    },
    isAllUtmTagsPresent() {
      return this.utmTagsRequired.every(tag => Object.keys(this.$route.query).includes(tag));
    },
    fbPixelId() {
      return window.location.host === config.siteHost ? config.fbPixel.try.prod : config.fbPixel.try.dev;
    },
    offerHash() {
      return window.location.host === config.siteHost ? config.offerHash.try : null;
    }
  },
  methods: {
    getNow() {
      this.gtagEvent('Button_click', {event_category: 'Click', event_label: 'Button_click_Get_Started'});
      this.amplitudeEvent('Get started clicked');

      this.amplitudeEvent('Congratulations shown');
      this.gtagEvent('Page_View', {event_category: 'View', event_label: 'Page_View_Congratulations'});

      if (this.isRedirectScenario) {
        this.redirectToAppStore();
      } else {
        this.$bvModal.show('get_now_modal');
      }
    },
    redirectToAppStore() {
      location.href = config.appStoreUrl;
    },
    playVideo() {
      const videoEl = this.$refs.youtubeVideo;
      if (videoEl) {
        videoEl.scrollIntoView({behavior: 'smooth'});
        this.player.playVideo();
      }
    },
    async submit() {
      this.gtagEvent('Button_click', {event_category: 'Click', event_label: 'Button_click_Get_Access'});
      this.amplitudeEvent('Get access clicked', {email: 'valid'});

      if (!this.isAuthKeyInCookies || !this.isEmailExistsInCookies) {
        await this.$store.dispatch('appStore/preAuth', this.email).then(response => {
          this.$cookies.set('auth_key', response.data.auth_key, '1m', '/', config.siteHost, true, 'Lax');
          this.$cookies.set('auth_uuid', response.data.uuid, '1m', '/', config.siteHost, true, 'Lax');
          this.$cookies.set('auth_email', this.email, '1m', '/', config.siteHost, true, 'Lax');
        });
      }

      location.href = `https://${config.shopHost}/payment/membership#try`;
    },
    setAuthHashCookie() {
      this.$cookies.set('auth_hash', this.offerHash, '1m', '/', config.siteHost, true, 'Lax');
    }
  }
};
</script>

<style lang="scss" scoped>
.try-wrapper::v-deep {
  max-width: 1440px;

  .slick-track {
    display: flex !important;
  }

  .top-wrapper {
    //padding: 100px 150px;
    padding: 60px 0 80px 0;
    width: 100%;


    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //flex: 1 1 0;
      text-align: left;

      .pretitle {
        font-size: 22px;
      }

      .title {
        font-size: 46px;
      }

      .description {
        font-size: 20px;
      }

      .get-btn {
        margin-right: 15px;
      }

      .watch-video-btn {
        &:hover {
          border-color: #FFF;
        }
      }
    }

    .image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //flex: 1 1 0;
    }
  }

  .features-wrapper {
    .feature {
      border-radius: 12px;

      img {
        width: 40px;
      }
    }

    .get-btn {
      padding-right: 75px;
      padding-left: 75px;
    }
  }

  .fixed-btn-wrapper {
    display: none;
  }
}
</style>

<style lang="scss">
#get_now_modal {
  input.try-text-input {
    height: 52px;
    border: 1px solid #C1C1C1;
    border-radius: 12px;

    &:focus-visible {
      border: 2px solid #C1C1C1;
      outline: 0;
    }
  }

  .agreements-msg {
    font-size: 13px;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .try-wrapper::v-deep {
    .top-wrapper {
      padding: 50px 25px;
      flex-direction: column-reverse;

      .top-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image {
          display: flex;
          flex-direction: row;
          justify-content: center;
          order: -1;
        }
      }

      .text {
        margin-top: 50px;
        text-align: center;

        .pretitle {
          font-size: 18px;
        }

        .title {
          font-size: 32px;
        }

        .description {
          font-size: 20px;
        }

        .get-btn {
          display: none;
          margin-right: 0;
        }

        .watch-video-btn {
          display: none;
        }
      }

      .image {

        img {
          //width: 300px;
          width: 100%;
          height: intrinsic;
        }
      }
    }

    .video-wrapper {
      .video-frame {
        width: 300px;
      }
    }

    .features-wrapper {
      padding: 0 15px;

      .get-btn {
        display: none;
      }
    }

    .fixed-btn-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      bottom: 0;
    }
  }
}
</style>