<template>
  <VueSlickCarousel class="comments-carousel" :slidesToShow="slidesToShow" :responsive="responsive" :arrows="false" :draggable="false">
    <div v-for="(comment, idx) in comments" :key="idx" class="comment d-flex flex-column justify-content-between text-left p-4">
      <div class="text">{{ comment.text }}</div>
      <div class="user d-flex align-items-center mt-3">
        <div class="avatar me-3">
          <img :src="require(`@/assets/img/${comment.avatar}`)" alt="">
        </div>
        <div class="name">
          <div class="fw-bold">{{ comment.name }}</div>
          <div class="small">{{ comment.nickname }}</div>
        </div>
      </div>
    </div>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  components: {
    VueSlickCarousel
  },
  data: () => ({
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          draggable: false
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          draggable: true
        }
      }
    ],
    comments: [
      {
        avatar: 'avatar-1.png',
        name: 'Sara Heiles',
        nickname: '@sara_hey',
        text: 'I’m having issues with my landlord, I needed to record our conversation because he hasn’t been doing what he says he will and then goes back and says I never told him. I was surprised when the iPhone voice recorder didn’t work on calls. I tried another app as well but this was the golden ticket! Thanks!'
      },
      {
        avatar: 'avatar-2.png',
        name: 'Olga Verner',
        nickname: '@verner',
        text: 'The app is great for those days where everyone is calling you and your half asleep and likely to only remember a few things said. So this app can help keep you on track and doing the things you want and need to be without worrying about what I am forgetting?'
      },
      {
        avatar: 'avatar-3.png',
        name: 'Tom Poppins',
        nickname: '@poppins_t',
        text: 'I’m still getting used to picking the correct choices in the correct order for incoming calls and outgoing calls. For some reason I can barely hear the other person on the phone while Call Recorder is recording. And I don’t believe they can hear me. Are my Settings wrong? I’ll research it. All in all I like your app'
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.comments-carousel::v-deep {
  .comment {
    height: 275px;
    width: auto !important;
    margin: 0 10px;
    border: 1px solid #C1C1C1;
    border-radius: 12px;

    .text {
      font-size: 15px;
    }

    .user {
      .avatar {
        img {
          width: 50px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .comments-carousel::v-deep {
    .comment {
      padding: 50px 25px;

      .text {
        font-size: 14px;
      }
    }
  }
}
</style>
